/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSpeech = /* GraphQL */ `
  mutation CreateSpeech(
    $input: CreateSpeechInput!
    $condition: ModelSpeechConditionInput
  ) {
    createSpeech(input: $input, condition: $condition) {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateSpeech = /* GraphQL */ `
  mutation UpdateSpeech(
    $input: UpdateSpeechInput!
    $condition: ModelSpeechConditionInput
  ) {
    updateSpeech(input: $input, condition: $condition) {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpeech = /* GraphQL */ `
  mutation DeleteSpeech(
    $input: DeleteSpeechInput!
    $condition: ModelSpeechConditionInput
  ) {
    deleteSpeech(input: $input, condition: $condition) {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;

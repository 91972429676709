/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpeech = /* GraphQL */ `
  query GetSpeech($id: ID!) {
    getSpeech(id: $id) {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listSpeechs = /* GraphQL */ `
  query ListSpeechs(
    $filter: ModelSpeechFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeechs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        speech
        time
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

import React from "react";
import {Text, View} from "react-native";
import styles from "../styles/styles";

const Message = ({id, time, message}) => (
  <View id={id} style={styles.message}>
    <Text style={{marginRight: '15em'}} numberOfLines={12}>{message}</Text>
    <Text style={styles.messageTime}>{time}</Text>
  </View>
)

export default Message;

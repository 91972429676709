import React, {Component, createRef} from 'react';
import '../styles/styles.css';
import {API, graphqlOperation} from 'aws-amplify';
import { listSpeechs } from '../graphql/queries';
import { createSpeech as createSpeechMutation } from '../graphql/mutations';
import {View, TextInput, Text, TouchableOpacity} from "react-native";
import styles from "../styles/styles";

class MicCheck extends Component {

  constructor(props) {
    super(props);
    this.textAreaRef = createRef();
  }

  componentDidMount() {
    this.getAllSpeech();
  }

  state = {
    form: '',
  }

  async getAllSpeech() {
    const result = await API.graphql(graphqlOperation(listSpeechs));
    result.data.listSpeechs.items
      .sort(function(i1, i2){
        if (i1.time > i2.time) return 1;
        else return -1;
      })
      .forEach(item => {
        this.props.addToSpeeches({id: item.id, speech: item.speech, time: item.time });
      });
  }

  createSpeech = async (_) => {
    const now = new Date()
    const utcMillisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
    const utcSecondsSinceEpoch = Math.round(utcMillisecondsSinceEpoch / 1000)
    const formDataWithTime = {'speech': this.state.form, 'time': now, 'ttl': utcSecondsSinceEpoch + 10}; // 900 seconds = 15 minutes
    await API.graphql({query: createSpeechMutation, variables: {input: formDataWithTime}});
    this.setState({form: ''})
  }

  handleOnPress = async (_) => {
    if (!this.state.form) return;
    await this.createSpeech();
  }

  handleEnterKeyPress = async (e) => {
    if (e.code === "Enter") {
      await this.createSpeech();
      this.textAreaRef.current.focus();
    }
  }

  render() {
    return (
      <View style={styles.inputContainer}>
        <TextInput
          ref={this.textAreaRef}
          maxLength={'128'}
          autoFocus={true}
          style={styles.textArea}
          onChangeText={text => {
            this.setState({form: text})
          }}
          onKeyPress={this.handleEnterKeyPress}
          keyboardType="default"
          placeholder="Practice First Freedom . . ."
          value={this.state.form}
        />
        <TouchableOpacity
          style={styles.button}
          onPress={this.handleOnPress}>
          <Text style={styles.text}>Speak</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default MicCheck;

import React from 'react';
import { View } from "react-native-web";

import { ReactComponent as Logo } from '../images/logo.svg';

const WrappedLogo = () => (
  <View style={{ aspectRatio: 1 }}>
    <Logo width={'6em'} height={'6em'} display={'flex'}/>
    Hello Jim, this is just a test site!
  </View>
);

export default WrappedLogo;
import React, { Component } from "react";
import styles from "../styles/styles";
import {API} from "aws-amplify";
import {onCreateSpeech, onDeleteSpeech} from "../graphql/subscriptions";
import Message from "./Message";
import {ScrollView, View} from "react-native";

class OpenForum extends Component {

  constructor(props) {
    super(props);
    this.subscribeToSpeeches()
    this.scroll = null;
  }

  state = {
    onCreateSubscription: Object,
    onDeleteSubscription: Object,
  }

  subscribeToSpeeches() {
    const onCreateSubscription = API.graphql({query: onCreateSpeech})
      .subscribe({
        next: messageData => {
          const item = messageData.value.data.onCreateSpeech;
          this.props.addToSpeeches({id: item.id, speech: item.speech, time: item.time})
        }
      });
    const onDeleteSubscription = API.graphql({query: onDeleteSpeech})
      .subscribe({
        next: messageData => {
          console.log('DELETE: ', messageData)
          const item = messageData.value.data.onDeleteSpeech;
          this.props.removeFromSpeeches({id: item.id})
        }
      });
    this.setState({
      onCreateSubscription: onCreateSubscription,
      onDeleteSubscription: onDeleteSubscription,
    })
  };

  render() {
    const dateFormatOptions = {
      day: 'numeric',
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };

    return (
      <View style={styles.textAreaContainer}>
        <ScrollView
          ref={(scroll) => {this.scroll = scroll;}}
          onContentSizeChange={() => this.scroll.scrollToEnd({ animated: true })}
          style={styles.scrollView}
        >
          {
            Array.from(this.props.speeches.entries()).map(x => (
              <Message key={x[0]} id={x[0]} time={new Intl.DateTimeFormat('en', dateFormatOptions).format(new Date(x[1].time))} message={x[1].speech}/>
            ))
          }
        </ScrollView>
      </View>
    );
  }
}

export default OpenForum;

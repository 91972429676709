/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSpeech = /* GraphQL */ `
  subscription OnCreateSpeech {
    onCreateSpeech {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpeech = /* GraphQL */ `
  subscription OnUpdateSpeech {
    onUpdateSpeech {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpeech = /* GraphQL */ `
  subscription OnDeleteSpeech {
    onDeleteSpeech {
      id
      speech
      time
      ttl
      createdAt
      updatedAt
    }
  }
`;

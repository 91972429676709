import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    overflow: 'auto',
    display: 'flex',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    border: '0px solid #83786E',
    color: '#1D2021',
    backgroundColor: '#1D2021',
  },
  titleContainer: {
    borderRadius: '5px',
    border: '2px solid #83786E',
    margin: '0.50em',
    width: '86%',//'var(--width, 28.75rem)',
    backgroundColor: '#83A598',
    position: "relative",
    padding: '0.25em',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  inputContainer: {
    // position: 'sticky',
    alignItems: 'center',
    margin: '2em 1.5em 1.5em',
    padding: '1em',
    flexDirection: 'row',
    width: '86%',
    justifyContent: 'space-between',
  },
  textAreaContainer: {
    display: 'flex',
    flex: 1,
    width: '86%',
    height: '33%',
  },
  textArea: {
    height: '2em',
    width: '100%',
    padding: '1em',
    margin: '8px 0',
    // boxSizing: 'border-box',
    color: '#83A598',
    // backgroundColor: '#83786E',
    placeholderTextColor: '#83A598',
    selectionColor: '#83A598',
    // borderColor: '#83A598',
    // borderRadius: '1em',
  },
  text: {
    // borderWidth: 1,
    // padding: 25,
    // borderColor: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: '#83A598',
    // backgroundColor: '#FB4934',
    width: '100%'
  },
  message: {
    // display: 'flex',
    // width: 0,
    flexGrow: 1,
    flex: 1,
    flexWrap: 'wrap',
    // flexShrink: '1',
    border: '2px solid #dedede',
    backgroundColor: '#f1f1f1',
    borderRadius: '5px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    margin: '0.25em',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    // width: '100%',
  },
  messageTime: {
    float: 'left',
    color: '#83786E'
  },
  button: {
    // backgroundColor: '#FB4934',
    transitionDuration: '0.4s',
    // color: '#1D2021',
    width: '50px'
  },
  thinLineBottom: {
    padding: '0.25em',
    // margin: '0.25',
    borderBottomWidth: 1,
    width: '86%',
    borderBottomColor: '#83786E',
  },
  thinLineTop: {
    padding: '0.25em',
    // margin: '0.25',
    borderTopWidth: 1,
    width: '86%',
    borderTopColor: '#83786E',
  },
  scrollView: {
    indicatorStyle: 'black',
  }
});


// .First-Button {
//   background-color: #FB4934;
//   transition-duration: 0.4s;
//   color: #1D2021;
//   border: 2px solid #83786E;
//   border-radius: 1em;


export default styles;
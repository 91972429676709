import React, { Component } from 'react';
import './styles/styles.css';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import Auth from "./components/Auth";
import {AmplifySignOut} from "@aws-amplify/ui-react/lib";
import styles from "./styles/styles";
import {SafeAreaView, View} from "react-native-web";
import WrappedLogo from "./components/WrappedLogo";
import MicCheck from "./components/MicCheck";
import OpenForum from "./components/OpenForum";

class FirstFreedom extends Component {
  state = {
    auth: Object,
    user: Object,
    speeches: new Map(),
  }

  addToSpeeches(speech) {
    this.setState({
      ...this.state,
      speeches: new Map(this.state.speeches.set(speech.id, {speech: speech.speech, time: speech.time}))
    })
  }

  removeFromSpeeches(id) {
    this.state.speeches.delete(id)
    this.setState({
      ...this.state,
      speeches: new Map(this.state.speeches)
    })
  }

  componentDidMount() {
    onAuthUIStateChange((nextAuthState, authData) => {
      this.setState({
        ...this.state,
        auth: nextAuthState,
        user: authData,
      })
    })
  }

  render() {
    return this.state.auth === AuthState.SignedIn && this.state.user ? (
      <SafeAreaView style={styles.container}>
        <View style={styles.titleContainer}>
          <WrappedLogo/>
          <AmplifySignOut/>
        </View>
        <View style={styles.thinLineTop}/>
        <OpenForum
          addToSpeeches={this.addToSpeeches.bind(this)}
          removeFromSpeeches={this.removeFromSpeeches.bind(this)}
          speeches={this.state.speeches}
        />
        <View style={styles.thinLineBottom}/>
        <MicCheck addToSpeeches={this.addToSpeeches.bind(this)}/>
      </SafeAreaView>
    ) : (
      <SafeAreaView style={styles.container}>
        <View style={styles.titleContainer}>
          <WrappedLogo/>
        </View>
        <Auth/>
      </SafeAreaView>
    );
  }
}

export default FirstFreedom;

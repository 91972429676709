import React from "react";
import {AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import '../styles/styles.css';

function Auth() {
  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn
          headerText=''
          submitButtonText='ENTER'
          slot="sign-in"
        />
        <AmplifySignUp
          formFields={[
            {
              type: "username",
              label: "Username",
              placeholder: "Some name no one will see",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              label: "Password",
              placeholder: "Just make it up",
              inputProps: { required: true, autocomplete: "new-password" },
            },
            {
              type: "email",
              label: "Email",
              placeholder: "This is just so I know you aren't a robot",
              inputProps: { required: true, autocomplete: "email" },
            },
          ]}
          headerText="Sign up, or don't."
          submitButtonText='freedom me'
          slot='sign-up'
        />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
}

export default Auth;